import React, { useEffect, useState } from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import queryString from 'query-string';

const CancelDonation = ({ location }) => {
    const {session_id} = queryString.parse(location.search);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(!session_id){
            setLoading(false)
            return;
        }

        fetch(`/.netlify/functions/cancel-checkout-session?session_id=${session_id}`)
        .then(res => res.json())
        .then(response => {
            window.location.href = "/donate"
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [session_id])

    return (
        <Layout location={location}>
            <Seo title="Thank you!" />
            <div className="aux-page">
                <div className="container w-100 m-auto text-center" style={{maxWidth: 700}}>
                    {loading ? (
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <div className="alert alert-danger" role="alert">
                            {!session_id ? "No checkout session id found." : "Cancel donation encounter an error."}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default CancelDonation
